.Rankings-container {
    margin: 0 auto;
    padding: 1rem;
    max-width: 80%;
    font-family: sans-serif;
  }
  
  .Rankings-container h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .Rankings-container table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .Rankings-container th, .Rankings-container td {
    padding: 0.5rem;
    /* border-bottom: 1px solid #ddd; */
  }
  
  .Rankings-container th {
    background-color: #f2f2f244;
  }
  
  .Rankings-container tr {
    margin: 200px;
    border-style:solid;
    border-color: white;
    border-radius: 10px;
  }

  .Rankings-container tr:hover {
    background-color: #f5f5f524;
    border-style:dashed;
    border-color: white;
    border-radius: 20px;
  }
  
  .Rankings-container .vote-button-up {
    margin: 0.5vw;
    padding: 1vw 2vw;
    border: none;
    border-radius: 1vw;
    background-color: #4CAF50;
    color: white;
    font-weight: bold;
    cursor: pointer;
    float: left;
  }
  
  .Rankings-container .vote-button-down {
    margin: 0.5vw;
    padding: 1vw 2vw;
    border: none;
    border-radius: 1vw;
    background-color: rgba(255, 0, 0, 0.536);
    color: white;
    font-weight: bold;
    cursor: pointer;
    float: left;
  }

  .karma-given-taken {
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: white;
      font-size: larger;
      font-weight: bold;
    
  }

  .karma-counter {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: larger;
    font-weight: bold;
    margin: 10px;
  
}
  
  .Rankings-container .vote-button-up:hover {
    background-color: #3e8e41;
  }
  
  .Rankings-container .vote-button-up:active {
    background-color: #2c682d;
  }

  .Rankings-container .vote-button-down:hover {
    background-color: rgb(64, 2, 2);
  }
  
  .Rankings-container .vote-button-down:active {
    background-color: #2c682d;
  }
  
  .Rankings-container .vote-count {
    font-weight: bold;
  }
  
  .Rankings-container td {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .Rankings-container th, .Rankings-container td {
      padding: 0.25rem;
    }
    
    .Rankings-container .vote-button {
      font-size: 0.8rem;
    }
  }

  .Rankings-container h2,h1 {
    margin: 3%;
    margin-bottom: 0px;
  }
  .Rankings-container h3 {
    margin-bottom: 0px;
  }