.App {
  text-align: center;
  background-image: linear-gradient(to top, darkred,grey,yellowgreen);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  color: grey;
}

a:visited {
  color:black;
}

.logo-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-logo {
  max-width: 100%;
  height: auto;
}

